import React, { useEffect } from 'react';

const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}
const on = (type, el, listener, all = false) => {
  let selectEl = select(el, all)
  if (selectEl) {
    if (all) {
      selectEl.forEach(e => e.addEventListener(type, listener))
    } else {
      selectEl.addEventListener(type, listener)
    }
  }
}
const scrollto = (el) => {
  let header = select('#header')
  let offset = header.offsetHeight

  if (!header.classList.contains('header-scrolled')) {
    offset -= 20
  }

  let elementPos = select(el).offsetTop
  window.scrollTo({
    top: elementPos - offset,
    behavior: 'smooth'
  })
}
window.addEventListener('load', () => {
  if (window.location.hash) {
    if (select(window.location.hash)) {
      scrollto(window.location.hash)
    }
  }
});

const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener)
}


const App = () => {
  let navbarlinks = null
  useEffect(()=>{
    let selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }

      
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
      on('click', '.mobile-nav-toggle', function(e) {
        select('#navbar').classList.toggle('navbar-mobile')
        this.classList.toggle('bi-list')
        this.classList.toggle('bi-x')
      })

      on('click', '.scrollto', function(e) {
        if (select(this.hash)) {
          e.preventDefault()
    
          let navbar = select('#navbar')
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
          scrollto(this.hash)
        }
      }, true)

      window.addEventListener('load', () => {
        if (window.location.hash) {
          if (select(window.location.hash)) {
            scrollto(window.location.hash)
          }
        }
      });

      navbarlinks = select('#navbar .scrollto', true)
      window.addEventListener('load', navbarlinksActive)
      onscroll(document, navbarlinksActive)
    }
  },[])
 
  const navbarlinksActive = () => {
    let position = window.scrollY + 200

    navbarlinks.forEach(navbarlink => {
      if (!navbarlink.hash) return
      let section = select(navbarlink.hash)
      if (!section) return
      if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
        navbarlink.classList.add('active')
      } else {
        navbarlink.classList.remove('active')
      }
    })
  }

  return (
    <>

<header id="header" class="fixed-top d-flex align-items-center header-transparent">
    <div class="container d-flex justify-content-between align-items-center">

      <div id="logo">
        <a href="index.html"><img src="./assets/logo_blue.svg" alt="" /></a>
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#services-heading">SERVICES</a></li>
          <li><a class="nav-link scrollto" href="#specialisation">SPECIALISATION</a></li>
          <li><a class="nav-link scrollto" href="#contact">CONTACT</a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>

  
  <section id="hero">
    <div class="hero-container" data-aos="zoom-in" data-aos-delay="100">
    <h1>IT Consultancy Services<br/>By<br/>Safar Services Ltd</h1>
    </div>
  </section>

  <main id="main">
  <section id="services-heading">
    <div class="section-header">
      <h3 class="section-title">Our Services & Solutions</h3>
      </div>
  </section>
  <section id="services">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
        {/* <h3 class="section-title">Our Services & Solutions</h3> */}
          <p class="section-description sec-one-desc">Safar Services Ltd is a London-based IT Consultancy Company specialising in tailored B2B solutions.<br/><br/>We consult on all aspects of the digital world:</p>
        </div>
        <div class="row solution-images">

           <div class="col-sm-9 solution-bg-container solution-bg-ipad">
            <div class="col-lg-6 col-md-12 col-sm-12 solution-bg solution-bg-fade1" data-aos="zoom-in">
                <div class="box">
                    <div>website development</div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 solution-bg solution-bg-ipad solution-bg-fade2" data-aos="zoom-in">
              <div class="box">
                  <div>digital marketing campaigns</div>
              </div>
            </div>
          </div>
          
          
          <div class="solution-bg-container solution2-bg-ipad">
              <div class="col-lg-3 col-md-6 solution-bg2 solution-bg-fade3" data-aos="zoom-in">
                <div class="box">
                    <div>cyber security</div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 solution-bg2 solution-bg-fade4" data-aos="zoom-in">
                <div class="box">
                    <div>technology audits</div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 solution-bg2 solution-bg-fade5" data-aos="zoom-in">
                <div class="box">
                    <div>cloud migration</div>
               
                </div>
              </div>
              <div class="col-lg-3 col-md-6 solution-bg2 solution-bg-fade6" data-aos="zoom-in">
                <div class="box">
                    <div>IT relocation</div>
                </div>
              </div>
          </div>

          <div class="col-lg-9 solution-bg-container ">
            <div class="col-lg-6 col-md-5 solution-bg solution-bg-fade1" data-aos="zoom-in">
              <div class="box">
                  <div>IT project management</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-5 solution-bg solution-bg-fade2" data-aos="zoom-in">
              <div class="box">
                  <div>architecture planning</div>
              </div>
            </div>
          
          </div>
          
          <div class="solution-bg-container solution2-bg-ipad">
            <div class="col-lg-6 col-md-12 solution-bg3 solution-bg-fade4" data-aos="zoom-in">
              <div class="box">
                  <div>vendor management</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 solution-bg3 solution-bg-fade5" data-aos="zoom-in">
              <div class="box">
                  <div>disaster recovery</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 solution-bg3 solution-bg-fade3" data-aos="zoom-in">
              <div class="box">
                  <div>data backup solutions</div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>

    <section id="specialisation">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
          <h3 class="section-title">SPECIALISATION</h3>
          <p class="section-description">Safar Services Ltd have decades of experience in the UK, European and US IT Services and Education Industries.<br/><br/>If you need to develop a website, or are embarking upon a digital marketing campaign, whether you need to refresh your business strategy or select the best route to market, we will provide you with our independent, low cost and honest experience to achieve your objectives.<br/><br/>Contact us for a free, no obligation consultation and see the benefits you may gain from our experience. </p>
        </div>
        <div class="row services-images">
          <div class="col-lg-4 col-md-6" data-aos="zoom-in">
            <div class="box">
               <img src="./assets/itBusiness_icon.svg" alt="" />
               <div class="services-images-text">Business<br/>Websites</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="zoom-in">
            <div class="box">
                <img src="./assets/sales_icon.svg" alt="" />
                <div class="services-images-text">Marketing<br/>Solutions</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6" data-aos="zoom-in">
            <div class="box">
                 <img src="./assets/customer_icon.svg" alt="" />
                 <div class="services-images-text">Business<br/>Strategy</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact">
      <div class="container flip-card" data-aos="fade-up">
        <div class="section-header ">
        <h3 class="section-title">CONTACT</h3>
          <p class="section-description">Simply fill in your query in the form below and we will get back to you within 24 hours.</p>
          <div class="flip-card-inner">
          

          <div id="form-class" class="col-lg-8 col-md-12 form-class" >
            <div>
              <form method="post" class="php-email-form" name="form">
                <div class="form-group">
                  <label class="form-label">NAME</label>
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required  autocomplete="off"/>
                </div>
                <div class="form-group mt-3">
                  <label class="form-label">EMAIL</label>
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" autocomplete="off" required />
                </div>
                <div class="form-group mt-3">
                  <label class="form-label">TELEPHONE</label>
                  <input type="text" class="form-control" name="telephone" id="telephone" placeholder="Telephone" required />
                </div>
                <div class="form-group mt-3">
                  <label class="form-label">BRIEF</label>
                  <textarea id="message" class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                </div>
                <div class="my-3">
                  {/* <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Your message has been sent. Thank you!</div> */}
                </div>
                <div class="text-center"><button type="button" id="submit" class="submit">SEND</button></div>
              </form>
            </div>
          </div>
            <div id="error" class="error">Please fill the form fields.</div>    
            <div class="col-lg-8 col-md-12 successMsg-class">
                <div id="successMsgIcon" class="section-description"> <img src="./assets/thumbUp.svg"  alt="" /></div>
                <div id="successMsgText" class="section-description">Thank you. Your message has been received. You will be contacted within next 24 hours.</div>
            </div>
          </div>       
          <p class="contact-section-sub-title">Alternatively, call or email us.</p>
          <p class="contact-section-phone">0207 088 8430<br/><a href="mailto:info@safarservices.com">info@safarservices.com</a></p>
        
        </div>
      </div>
    </section>
    {/* <section id="successMsg">
        <div class="container" data-aos="fade-up">
          <div class="section-header">
            <div class="col-lg-8 col-md-12 successMsg-class">
              <div id="successMsgIcon" class="section-description"> <img src="./assets/thumbUp.svg"  alt="" /></div>
              <div id="successMsgText" class="section-description">Thank you. Your message has been received. You will be contacted within next 24 hours.</div>
            </div>
          </div>
       </div>
    </section> */}
    <section id="quote">
      <div class="container" data-aos="fade-up">
        <div class="section-header">
          <h2 class="section-title quote">“We must all obey the great law of change.<br/>It is the most powerful law of nature.”</h2>
          <p class="section-description">-Edmund Burke</p>
        </div>
      </div>
    </section>
    {/* <section class="row contact-divider"></section> */}
    <section id="contact-footer">
      <div class="container mt-5">
      

        <div class="row justify-content-center">

          <div class="col-lg-4 col-md-10">

            <div class="info">
              <div>
                <img src="./assets/location_icon.svg"  alt="" />
                <p>Safar Services Ltd <br/>83 Baker Street <br/>London <br/>W1U 6AG <br/>UK</p>
              </div>

              <div>
                <img src="./assets/contact_icon.png"  alt="" />
                <p>0207 088 8430</p>
              </div>

              <div>
              <img src="./assets/mail_icon.svg"  alt="" />
                <p><a href="mailto:info@safarservices.com">info@safarservices.com</a></p>
              </div>
              <div>
                <p class="contact-section-logo"><img src="./assets/logo-footer.svg"  alt=""/><br/><br/></p>
              </div>
             
             
            </div>

           </div>

          <div class="col-lg-5 col-md-8 map-image">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.6298903303277!2d-0.1593023234963123!3d51.52000577181615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761acc26d8cd8b%3A0x54ca7bd129c00aed!2s83%20Baker%20St%2C%20London%20W1U%206AG%2C%20UK!5e0!3m2!1sen!2sin!4v1718094022219!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

        </div>

      </div>
    </section>

  </main>
  
  <footer id="footer">


    <div class="container">
      <div class="copyright">
        &copy; Copyright 2024, All rights reserved, Safar Services Ltd. Company registered in England. Co Reg No - 11339227
      </div>

    </div>
  </footer>

    </>
  );
}

export default App